import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const LandscapePro = () => {
  const headingbanner = {
    title: `Landscape Pro`,
    content: `A Property Management Platform`,
  };

  const data = {
    images: ["landscape-pro.webp", "landscape-pro-2.webp"],
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "Design Audit"],
      },
      {
        title: "Platform",
        text: ["Web Application", "Mobile Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `In their old platform, the crew managers struggled with task assignment and follow-up. It was not possible to calculate the real time crew travel and their working hours. `,
          `Tasks such as adding new jobs, properties and crew-members became time-consuming. The lack of task prioritisation exacerbates the problem.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `We transformed their crew management process. With our solution, we simplified the process of adding new jobs, properties and crew members which also enables the user to prioritise their task for optimised efficiency. `,
          `The crew managers can now effortlessly assign and track tasks, calculate real-time working hours of the team, etc. Overall, we streamlined their operations by offering intuitive solutions.`,
        ],
      },
    ],
    image: ["landscape-pro-problem.webp"],
  };

  const projectData = {
    title: `Seamless Crew Task Scheduling`,
    para: [
      `Crew managers can efficiently schedule work for their teams, ensuring optimal productivity. `,
    ],
    content: {
      image: ["landscape-pro-projectone.webp"],
      text: `Additionally, crew leaders can utilize our mobile app to mark attendance, receive tasks, access property details, navigate routes, and complete tasks enhancing collaboration and streamlining operations.`,
    },
    content2: {
      image: ["landscape-pro-projectone-2.webp"],
      text: `This mobile version has helped the crew leaders to improve their productivity as they can now schedule the task for their day and offer enhanced services efficiently and effectively.`,
    },
  };

  const projectData2 = {
    title: `Crew Management Platfrom `,
    para: [
      `Presenting our revamped crew management platform, designed for higher management to check overall performance.`,
      `Crew managers now have the power to accept new properties, seamlessly integrate them into the system, edit property details, efficiently manage jobs and schedules, handle timesheets, add user information, manage crews, and assign roles to users.`,
      `This platform will elevate productivity and streamline operations effortlessly.`,
    ],
    content: {
      image: ["landscape-pro-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: `Property Management Made Simple`,
    para: [
      `Experience streamlined efficiency with our comprehensive Jobs, Property, and Property Details module.`,
      `The platform seamlessly manage all aspects of properties, from adding new properties and editing details to scheduling tasks and managing jobs. `,
    ],
    content: {
      image: [
        "landscape-pro-projectthree.webp",
        "landscape-pro-projectthree-2.webp",
        "landscape-pro-projectthree-3.webp",
        "landscape-pro-projectthree-4.webp",
        "landscape-pro-projectthree-5.webp",
        "landscape-pro-projectthree-6.webp",
        "landscape-pro-projectthree-7.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `We successfully addressed the crew management challenges faced by the organisation through our transformative solution. `,
        `By simplifying the process of adding new jobs, properties, and crew members, we reduced time-consuming tasks and improved efficiency. `,
        `The ability to prioritise tasks has also enhanced overall operations by optimising resource allocation.`,
        `With our solution, crew managers can effortlessly assign and track tasks, calculate real-time working hours, and streamline their operations.`,
        `The intuitive nature of our solution has resulted in a significant improvement in crew management processes, leading to increased productivity and effectiveness.`,
      ],
      list: [
        `Crew Management Processes`,
        `Leading To Increased Productivity`,
        `Effectiveness`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={15} />
      </div>
    </Layout>
  );
};

export default LandscapePro;

export const Head = () => (
  <Seo
    title="LandScape Pro- Octet Design Studio"
    description="An end-to-end automation solutions for property management. We did user research, conducted design audit and offered UI UX Designing Services."
  />
);
